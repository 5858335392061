import {RealEstate} from "../../models/RealEstate";
import {Province} from "../../models/Province";
import {CompanyAgreement} from "../../models/CompanyAgreement";
import {City} from "../../models/City";

export class Response {
    constructor(apiResponse) {
        this._errors = apiResponse['errors'];
        this._result = apiResponse['result'];
    }

    fieldErrors() {
        return this.fieldErrorsOf(this._errors);
    }

    nonFieldErrors() {
        return this.nonFieldErrorsOf(this._errors)
    }

    hasErrors() {
        return this._errors && Object.keys(this._errors).length > 0;
    }

    fieldErrorsOf(errorDictionary) {
        let errors = {};
        if(errorDictionary) {
            Object.keys(errorDictionary).forEach((currentField) => {
                if(currentField !== 'non_field_errors') {
                    let mappedField = this.mapFields(currentField);
                    errors[mappedField] = errorDictionary[currentField][0];
                }
            });
        }

        return errors
    }

    nonFieldErrorsOf(errorDictionary) {
        if (errorDictionary) {
            return errorDictionary['non_field_errors'] || [];
        }

        return []
    }

    mapFields(name) {
        throw new Error("You have to implement the method");
    }
}

export class GetPropertyResponse {
    constructor(apiResponse) {
        this._successful = apiResponse['resultado'] === 'OK';

        this.initializePropertyData(apiResponse);
    }

    isSuccessful() {
        return this._successful;
    }

    initializePropertyData(apiResponse) {
        this._propertyData = {};

        if(this.isSuccessful()) {
            let data = apiResponse['inmueble'];

            for(let key in data) {
                const mappedKey = this.mapField(key);
                this._propertyData[mappedKey] = data[key];
            }
        }
    }

    propertyData() {
        return this._propertyData;
    }

    mapField(name) {
        const fieldMap = {
            'domicilio_inmueble_calle': 'addressStreet',
            'domicilio_inmueble_numero': 'addressNumber',
            'domicilio_inmueble_piso': 'addressFloor',
            'domicilio_inmueble_departamento': 'addressApartment',
            'domicilio_inmueble_localidad': 'addressCity',
            'domicilio_inmueble_provincia': 'addressProvince',
            'tipo_de_alquiler': 'rentalType',
            'duracion_alquiler': 'rentalLength',
            'periodo_de_alquiler': 'rentalPeriod',
            'alquiler_pactado_periodo_1': 'amountPeriod1',
            'alquiler_pactado_periodo_2': 'amountPeriod2',
            'alquiler_pactado_periodo_3': 'amountPeriod3',
            'alquiler_pactado_periodo_4': 'amountPeriod4',
            'alquiler_pactado_periodo_5': 'amountPeriod5',
            'alquiler_pactado_periodo_6': 'amountPeriod6',
            'alquiler_pactado_periodo_7': 'amountPeriod7',
            'alquiler_pactado_periodo_8': 'amountPeriod8',
            'alquiler_pactado_periodo_9': 'amountPeriod9',
            'expensas_pactadas': 'expenses',
            'inmobiliaria_id': 'realState',
            'es_prorroga_o_renovacion': 'isContractExtension',
        };

        return fieldMap[name];
    }
}

export class GetRealEstateResponse extends Response {
    realEstate() {
        let realEstate = [];
        for (let realEstateInfo of this._result['inmobiliarias']) {
            realEstate.push(new RealEstate(
                realEstateInfo['id'], realEstateInfo['nombre'], realEstateInfo['domicilio'],
                realEstateInfo['telefono'], realEstateInfo['email'], realEstateInfo['web'],
                realEstateInfo['localidades'], realEstateInfo['provincias'], realEstateInfo['url_logo']));
        }

        return realEstate;
    }

    amountOfRealEstates(){
        return this._result['total'];
    }
}

export class GetProvincesAndCitiesResponse extends Response {
    constructor(apiResponse) {
        super(apiResponse);

        this._provinces = [];
        this._cities = [];

        this._obtainCitiesAndProvincesFromResult();
    }

    _obtainCitiesAndProvincesFromResult() {
        for (let provinceCode in this._result['localidades']) {
            const provinceName = this._result['provincias'][provinceCode];
            const citiesInProvince = this._result['localidades'][provinceCode];

            const province = new Province(provinceCode, provinceName);

            citiesInProvince.forEach((cityData) => {
                this._cities.push(new City(cityData.id, cityData.nombre, province));
            });

            this._provinces.push(province);
        }

        const nullProvince = new Province('', '', []);
        this._provinces.push(nullProvince);
    }

    provinces() {
        return this._provinces;
    }

    cities() {
        return this._cities;
    }
}

export class GetDatosLocacionChoicesResponse extends Response {
    constructor(apiResponse) {
        super(apiResponse);

        this._frecuencia_de_indexacion = [];
        this._tipo_de_alquiler = [];
        this._duracion_en_meses = []
        this._moneda_de_alquiler = []
        this._tipo_de_indexacion_ARG = {
            "PREESTABLECIDA": "Valores acordados",
            "INDEXACION_IPC_RIPTE": "Índice IPC / RIPTE",
            "INDEXACION_RIPTE": "Índice RIPTE",
            "INDEXACION_IPC": "Índice IPC",
            "INDEXACION_CSV_CER": "Índice Casa Propia"
        }

        this._tipo_de_indexacion_USD = {
            "SIN AUMENTO": "Sin Indexar",
        }

        this._obtain_frecuencia_de_indexacion();
        this._obtain_tipo_de_alquiler();
        this._obtain_duracion_en_meses();
        this._obtain_moneda_de_alquiler();
        this._obtain_tipo_de_indexacion_ARG();
        this._obtain_tipo_de_indexacion_USD();
    }

    _obtain_frecuencia_de_indexacion() {
        this._frecuencia_de_indexacion = Object.keys(this._result['frecuencia_de_indexacion']).map(key => ({
            value: key,
            label: this._result['frecuencia_de_indexacion'][key]
        }));
    }

    _obtain_tipo_de_alquiler() {
        this._tipo_de_alquiler = Object.keys(this._result['tipo_de_alquiler']).map(key => ({
            value: key,
            label: this._result['tipo_de_alquiler'][key]
        }));
    }

    _obtain_duracion_en_meses() {
        this._duracion_en_meses = Object.keys(this._result['duracion_en_meses']).map(key => ({
            value: key,
            label: this._result['duracion_en_meses'][key]
        }));
    }

    _obtain_moneda_de_alquiler() {
        this._moneda_de_alquiler = Object.keys(this._result['moneda_de_alquiler']).map(key => ({
            value: key,
            label: this._result['moneda_de_alquiler'][key]
        }));
    }

    _obtain_tipo_de_indexacion_ARG() {
        this._tipo_de_indexacion_ARG = Object.keys(this._tipo_de_indexacion_ARG).map(key => ({
            value: key,
            label: this._tipo_de_indexacion_ARG[key]
        }));
    }

    _obtain_tipo_de_indexacion_USD() {
        this._tipo_de_indexacion_USD = Object.keys(this._tipo_de_indexacion_USD).map(key => ({
            value: key,
            label: this._tipo_de_indexacion_USD[key]
        }));
    }

    frecuencia_de_indexacion() {
        return this._frecuencia_de_indexacion;
    }

    tipo_de_alquiler() {
        return this._tipo_de_alquiler;
    }

    duracion_en_meses() {
        return this._duracion_en_meses;
    }

    moneda_de_alquiler() {
        return this._moneda_de_alquiler;
    }

    tipo_de_indexacion_ARG() {
        return this._tipo_de_indexacion_ARG;
    }

    tipo_de_indexacion_USD() {
        return this._tipo_de_indexacion_USD;
    }

 
}

export class GetDatosLocacionValidateResponse extends Response {
    constructor(apiResponse) {
        super(apiResponse);

        this._validate = false;
        this._warranty = {};
        this._email = {};
        this._contact = {};

        this._obtain_validate();
        this._obtain_warranty();
        this._obtain_email();
        this._obtain_contact();
    }

    _obtain_validate() {
        this._validate = this._result?.warranty ? true : false
    }

    _obtain_warranty() {
        this._warranty = this._result?.warranty
    }

    _obtain_email() {
        this._email = this._result?.office?.email
    }

    _obtain_contact() {
        this._contact = this._result?.contact
    }
   
    validate() {
        return this._validate;
    }

    warranty() { 
        return this._validate;
    }

 
}

export class GetDatosLocacionDetailsResponse extends Response {
    constructor(apiResponse) {
        super(apiResponse);

        this._details = [];

        this._obtain_details();
    }

    _obtain_details() {
        this._details = this._result
    }

    details() {
        return this._details;
    }
}

export class GetBranchOffice extends Response {
    constructor(apiResponse) {
        super(apiResponse);

        let oficina_comercial = apiResponse['oficina_comercial'];
        this._name = oficina_comercial['oficina_comercial_nombre'];
        this._telephone_number = oficina_comercial['telefonos'];
        this._email = oficina_comercial['email'];
        this._location = oficina_comercial['oficina_comercial_ubicacion'];
        this._applicant_name = apiResponse['nombre_solicitante'];
        this._type = oficina_comercial['tipo_de_oficina'];
        this._image_url = oficina_comercial['url_imagen_ubicacion'];
        this._warranty_number = apiResponse['numero_de_solicitud'];
    }

    branch_office_name() {
        return this._name
    }

    branch_office_telephone_number() {
        return this._telephone_number
    }

    branch_office_email() {
        return this._email
    }

    branch_office_location() {
        return this._location
    }

    applicant_name() {
        return this._applicant_name
    }

    warranty_number() {
        return this._warranty_number
    }

    branch_office_type() {
        return this._type
    }

    branch_office_image_url() {
        return this._image_url
    }
}

export class GetCompanyAgreementsResponse extends Response {
    constructor(apiResponse) {
        super(apiResponse);

        this._initializeCompanyAgreements();
    }

    _initializeCompanyAgreements() {
        this._agreements = [];

        this._result['convenios'].forEach((agreementData) => {
            const companyAgreement = new CompanyAgreement(agreementData.id, agreementData.alias, agreementData.empresa,
                agreementData.cuit_empresa);
            this._agreements.push(companyAgreement)
        });
    }

    companyAgreements() {
        return this._agreements;
    }
}


export class NewReclaimResponse extends Response {
    fieldErrors() {
        if(this.hasErrors()) {
            let renterErrors = this.fieldErrorsOf('inquilino', this._errors.inquilino);
            let ownerErrors = this.fieldErrorsOf('propietario', this._errors.propietario);
            let reclaimErrors = this.fieldErrorsOf('incumplimiento', this._errors.incumplimiento);

            return {...renterErrors, ...ownerErrors, ...reclaimErrors}
        }

        return {}
    }

    nonFieldErrors() {
        return this.nonFieldErrorsOf(this._errors.inquilino) + this.nonFieldErrorsOf(this._errors.propietario) +
            this.nonFieldErrorsOf(this._errors.incumplimiento)
    }

    fieldErrorsOf(prefix, errorDictionary) {
        if(errorDictionary) {
            return Object.keys(errorDictionary).reduce((errors, currentField) => {
                if(currentField !== 'non_field_errors') {
                    let mappedFields = this.mapFields(prefix, currentField);

                    mappedFields.forEach((field) => { errors[field] = errorDictionary[currentField][0]; });
                }

                return errors;
            }, {})
        }

        return {}
    }

    mapFields(prefix, name) {
        let mapping = {
            propietario: {
                nombre: ['firstName'],
                apellido: ['lastName'],
                num_doc: ['idNumber'],
                telefono_codigo: ['phoneAreaCode'],
                telefono_numero: ['phoneNumber'],
                celular_codigo: ['celAreaCode'],
                celular_numero: ['celNumber'],
                email_1: ['email'],
                email_2: ['email2'],
            },
            inquilino: {
                nombre: ['renterFirstName'],
                apellido: ['renterLastName'],
                dni: ['renterIdNumber'],
            },
            incumplimiento: {
                domicilio_propiedad_calle: ['addressStreet'],
                domicilio_propiedad_numero: ['addressNumber'],
                domicilio_propiedad_piso: ['addressFloor'],
                domicilio_propiedad_departamento: ['addressApartment'],
                provincia: ['province'],
                localidad: ['city'],
                fecha_firma_locacion: ['signedOnDay', 'signedOnMonth', 'signedOnYear'],
                observaciones: ['observations'],
            },
        };

        return mapping[prefix][name]
    }
}

export class NewRealStateResponse extends Response {
    mapFields(fieldName) {
        let mapping = {
            nombre: ['legalName'],
            domicilio: ['legalAddress'],
            codigo_area_telefono: ['phoneAreaCode'],
            telefono: ['phoneNumber'],
            email1: ['email1'],
            email2: ['email2'],
            sitio_web: ['website'],
            responsable: ['manager'],
            logo: ['logo'],
        };

        return mapping[fieldName]
    }
}

export class SendQuestionsResponse extends Response {
    mapFields(name) {
        let mapping = {
            nombre_completo: 'fullName',
            email: 'email',
            telefono: 'phoneNumber',
            consulta: 'question'
        };

        return mapping[name]
    }
}

export class SendRegretResponse extends Response {
    constructor(apiResponse) {
        super(apiResponse);
        this.arrepentimiento = apiResponse['arrepentimiento'];
    }

    mapFields(name) {
        let mapping = {
            email: 'email',
            nro_solicitud: 'nroSolicitud',
            num_doc: 'nroDocumento',
            tipo_doc: 'tipoDocumento',
            codigo: 'codigo',
        };

        return mapping[name]
    }
}


export class LoginResponse extends Response {
    mapFields(name) {
        let mapping = {
            tipo_doc: 'docType',
            num_doc: 'docNumber',
            codigo_solicitud: 'requestCode',
        };

        return mapping[name]
    }

    token() {
        return this._result['token'];
    }
}


export class GetRequestDetailsResponse extends Response {
    requestDetails() {
        const request = this._result['solicitud'][0];
        console.log(request)
        return {
            'state': request['estado'],
            'progress': request['porcentaje_de_avance'],
            'number': request['nro'],
            'applicant': request['solicitante']['nombre_completo'],
            'applicantEmail': request['solicitante']['email'],
            'address': request['domicilio'],
            'companyAgreement': request['convenio'],
            'office': {
                'name': request['oficina']['nombre'],
                'address': request['oficina']['domicilio'],
                'location': request['oficina']['ubicacion'],
                'phone': request['oficina']['telefono'],
                'email': request['oficina']['email'],
                'hasBankAccount' : request['oficina']['tiene_cuenta_bancaria']
            },
            'showPaymentMethod': request['mostrar_resumen_de_pago'],
            'showChoosePayment': request['mostrar_boton_de_pago'],
            'fee': request['honorarios'],
            'transferDiscount': request['descuento-pago-al-contado-por-transferencia'],
            'cashDiscount': request['descuento-pago-al-contado-en-efectivo'],
            'twoOrThreeInstallmentsDiscount': request['descuento-por-pago-en-2-o-3-cuotas'],
            'sixInstallmentsAdvanceAmount': request['monto_anticipo_para_plan_6_pagos'],
            'sixInstallmentsAmount': request['monto_cuota_para_plan_6_pagos'],
            'fiveInstallmentsAdvanceAmount': request['monto_anticipo_para_plan_5_pagos'],
            'fiveInstallmentsAmount': request['monto_cuota_para_plan_5_pagos'],
            'fourInstallmentsAdvanceAmount': request['monto_anticipo_para_plan_4_pagos'],
            'fourInstallmentsAmount': request['monto_cuota_para_plan_4_pagos'],
            'threeInstallmentsAdvanceAmount': request['monto_anticipo_para_plan_3_pagos'],
            'threeInstallmentsAmount': request['monto_cuota_para_plan_3_pagos'],
            'twoInstallmentsAdvanceAmount': request['monto_anticipo_para_plan_2_pagos'],
            'twoInstallmentsAmount': request['monto_cuota_para_plan_2_pagos'],
        }
    }

    mapFields(name) {
        let mapping = {
            codigo_solicitud: 'requestCode'
        };

        return mapping[name]
    }
}

export class NewWarrantyApplicationResponse {
    static from(apiResponse) {
        // Can't get all subclasses of a class in JS
        let subclasses = [SuccessfulWarrantyApplicationResponse, FieldErrorWarrantyApplicationResponse,
            AlreadyHasActiveWarrantyApplicationResponse];

        return subclasses.find(subclass => subclass.accepts(apiResponse))
    }

    static accepts(apiResponse) {
        throw new Error("Subclass responsibility");
    }

    constructor(apiResponse) {
        this.apiResponse = apiResponse;
        this.result = apiResponse['resultado'];
    }

    hasErrors() {
        throw new Error("You have to implement the method");
    }
}


export class SuccessfulWarrantyApplicationResponse extends NewWarrantyApplicationResponse {
    static accepts(apiResponse) {
        return apiResponse['resultado'] === 'OK';
    }

    warrantyApplicationNumber() {
        return this.apiResponse.solicitud.nro_solicitud;
    }

    hasErrors() {
        return false
    }
}


export class FieldErrorWarrantyApplicationResponse extends NewWarrantyApplicationResponse {
    static accepts(apiResponse) {
        return apiResponse['resultado'] !== 'OK' && apiResponse['codigo'] === 1;
    }

    hasErrors() {
        return true
    }

    errorCode() {
        return this.apiResponse.codigo;
    }

    fieldErrors() {
        let fieldErrors = {};
        const errorsDetail = this.apiResponse.detalle;
        Object.keys(errorsDetail).forEach(fieldName => {
            fieldErrors[this.mapFields(fieldName)] = errorsDetail[fieldName];
        });

        return fieldErrors;
    }

    mapFields(name) {
        let mapping = {
            "nombre": "name",
            "apellido": "surname",
            "email": "email",
            "email2": "email2",
            "como_nos_conocio": "poll",
            "celular_codigo": "phoneAreaCode",
            "celular_numero": "phoneNumber",
            "tipo_doc": "idType",
            "num_doc": "idNumber",
            "id_inmobiliaria": "realEstate",
            "zona_de_alquiler": "city"
        };

        return mapping[name]
    }
}


export class AlreadyHasActiveWarrantyApplicationResponse extends NewWarrantyApplicationResponse {
    static accepts(apiResponse) {
        return apiResponse['resultado'] !== 'OK' && apiResponse['codigo'] === 2;
    }

    hasErrors() {
        return true
    }

    errorCode() {
        return this.apiResponse.codigo;
    }

    activeWarrantyApplication() {
        return this.apiResponse.detalle[0].nro;
    }
}


export class CreateWarrantyPrequalificationResponse {
    static from(apiResponse) {
        // Can't get all subclasses of a class in JS
        let subclasses = [SuccessfulWarrantyPrequalificationResponse, FailedWarrantyPrequalificationResponse,
            FieldErrorWarrantyPrequalificationResponse];

        return subclasses.find(subclass => subclass.accepts(apiResponse))
    }

    constructor(apiResponse) {
        this.apiResponse = apiResponse;
    }

    warrantyPrequalificationNumber() {
        return this.apiResponse.precalificacion;
    }

    prequalify() {
        throw new Error("You have to implement the method");
    }

    hasErrors() {
        throw new Error("You have to implement the method");
    }
}


export class SuccessfulWarrantyPrequalificationResponse extends CreateWarrantyPrequalificationResponse {
    static accepts(apiResponse) {
        return apiResponse['resultado'] === 'OK' && apiResponse['codigo'] === 1;
    }

    prequalify() {
        return true;
    }

    hasErrors() {
        return false
    }

    warrantyPrequalificationNumber() {
        return this.apiResponse.pedido_id;
    }
}

export class FailedWarrantyPrequalificationResponse extends CreateWarrantyPrequalificationResponse {
    static accepts(apiResponse) {
        return apiResponse['resultado'] === 'OK' && apiResponse['codigo'] === 2;
    }

    prequalify() {
        return false;
    }

    hasErrors() {
        return false
    }

    warrantyPrequalificationNumber() {
        return this.apiResponse.pedido_id;
    }
}


export class FieldErrorWarrantyPrequalificationResponse extends CreateWarrantyPrequalificationResponse {
    static accepts(apiResponse) {
        return apiResponse['resultado'] !== 'OK';
    }

    prequalify() {
        return false;
    }

    hasErrors() {
        return true
    }

    fieldErrors() {
        let fieldErrors = {};
        const errorsDetail = this.apiResponse.detalle;
        Object.keys(errorsDetail).forEach(fieldName => {
            fieldErrors[this.mapFields(fieldName)] = errorsDetail[fieldName];
        });

        return fieldErrors;
    }

    mapFields(name) {
        let mapping = {
            "nombre": "name",
            "apellido": "surname",
            "mail": "email",
            "sexo": "sex",
            "celular": "phoneNumber",
            "num_doc": "idNumber",
            "ingresos": "income",
            "condicion_laboral": "employeeType",
            "antiguedad_laboral": "seniority",
            "valor_alquiler": "amountFirstPeriod",
            "expensas": "expenses",
        };

        return mapping[name]
    }
}


export class AddCoApplicantForPrequalificationResponse {
    static from(apiResponse) {
        // Can't get all subclasses of a class in JS
        let subclasses = [
            SuccessfullPrequalificationWithCoApplicantResponse,
            FailedPrequalificationWithCoApplicantResponse,
            FieldErrorCoApplicantForPrequalificationResponse
        ];

        return subclasses.find(subclass => subclass.accepts(apiResponse))
    }

    constructor(apiResponse) {
        this.apiResponse = apiResponse;
    }

    prequalify() {
        throw new Error("You have to implement the method");
    }

    coApplicantAdded() {
        throw new Error("You have to implement the method");
    }

    hasErrors() {
        throw new Error("You have to implement the method");
    }
}

export class SuccessfullPrequalificationWithCoApplicantResponse extends AddCoApplicantForPrequalificationResponse {
    static accepts(apiResponse) {
        return apiResponse['resultado'] === 'OK' && apiResponse['codigo'] === 1;
    }

    prequalify() {
        return true;
    }

    coApplicantAdded() {
        return true;
    }

    hasErrors() {
        return false;
    }
}

export class FailedPrequalificationWithCoApplicantResponse extends AddCoApplicantForPrequalificationResponse {
    static accepts(apiResponse) {
        return apiResponse['resultado'] === 'OK' && apiResponse['codigo'] !== 1;
    }

    prequalify() {
        return false;
    }

    coApplicantAdded() {
        return this.apiResponse.codigo === 2;
    }

    hasErrors() {
        return false;
    }
}

export class FieldErrorCoApplicantForPrequalificationResponse extends AddCoApplicantForPrequalificationResponse {
    static accepts(apiResponse) {
        return apiResponse['resultado'] !== 'OK';
    }

    prequalify() {
        return false;
    }

    coApplicantAdded() {
        return false;
    }

    hasErrors() {
        return true
    }

    fieldErrors() {
        let fieldErrors = {};
        const errorsDetail = this.apiResponse.detalle;
        Object.keys(errorsDetail).forEach(fieldName => {
            fieldErrors[this.mapFields(fieldName)] = errorsDetail[fieldName];
        });

        return fieldErrors;
    }

    mapFields(name) {
        let mapping = {
            "nombre": "name",
            "apellido": "surname",
            "sexo": "sex",
            "num_doc": "idNumber",
            "ingresos": "income",
            "condicion_laboral": "employeeType",
            "antiguedad_laboral": "seniority",
        };

        return mapping[name]
    }
}

export class GetParticipantsPersonalAndJobDataCheckedResponse {
    constructor(apiResponse) {
        this.apiResponse = apiResponse;
    }

    _participant(participantData) {
        return {
            personalDataChecked: participantData['datos_personales_controlados'],
            jobDataChecked: participantData['datos_laborales_controlados']
        }
    }

    applicant() {
        const applicantData = this.apiResponse.result.solicitante;
        return this._participant(applicantData)
    }

    coApplicants() {
        const coApplicants = this.apiResponse.result.cosolicitantes;

        let coApplicantsData = {};
        Object.entries(coApplicants).forEach(([coApplicantId, coApplicantData]) => {
            coApplicantsData[coApplicantId] = this._participant(coApplicantData);
        });

        return coApplicantsData;
    }
}

export class GetApplicantsResponse extends Response {
    _participant(participantData) {
        return {
            id: participantData['id'],
            firstName: participantData['nombre'],
            lastName: participantData['apellido'],
            email: participantData['email'],
            docType: participantData['tipo_doc'],
            docNumber: participantData['num_doc'],
            sex: participantData['sexo'],
            birthdate: participantData['fecha_nacimiento'],
            cuit: participantData['cuit'],
            addressStreet: participantData['domicilio_calle'],
            addressStreetNumber: participantData['domicilio_numero'],
            addressStreetFloor: participantData['domicilio_piso'],
            addressStreetApartment: participantData['domicilio_departamento'],
            addressProvince: participantData['domicilio_provincia'],
            addressCity: participantData['domicilio_ciudad'],
            phoneAreaCode: participantData['telefono_codigo'],
            phoneNumber: participantData['telefono_numero'],
            cellPhoneAreaCode: participantData['celular_codigo'],
            cellPhoneNumber: participantData['celular_numero'],
            employeeType: participantData['empleado_tipo'],
            currentCompany: participantData['empresa_ocu_actual'],
            currentCompanyCuit: participantData['cuit_empresa_ocu_actual'],
            jobAddressStreet: participantData['domicilio_calle_ocu_actual'],
            jobAddressStreetNumber: participantData['domicilio_numero_ocu_actual'],
            jobAddressStreetFloor: participantData['domicilio_piso_ocu_actual'],
            jobAddressStreetApartment: participantData['domicilio_departamento_ocu_actual'],
            jobLocality: participantData['localidad_ocu_actual'],
            jobAddressProvince: participantData['domicilio_provincia_ocu_actual'],
            jobAddressCity: participantData['domicilio_ciudad_ocu_actual'],
            jobPhoneAreaCode: participantData['telefono_codigo_ocu_actual'],
            jobPhoneNumber: participantData['telefono_numero_ocu_actual'],
            seniority: participantData['antiguedad_ocu_actual'],
            income: participantData['ingresos_monto'],
            liveInTheProperty: participantData['ocupa_inmueble'],
            documents: participantData['comprobantes'],
            controlledDocuments: participantData['documentos_completados'],
            stateEmail: participantData['_email']
        }
    }

    applicant() {
        const applicantData = this._result['solicitante'];
        return this._participant(applicantData)
    }

    coApplicants() {
        const coApplicants = this._result['cosolicitantes'];

        let coApplicantsData = [];
        coApplicants.forEach(coApplicant => {
            coApplicantsData.push(this._participant(coApplicant));
        });

        return coApplicantsData;
    }
}

export class EditWarrantyApplicantionResponse {
    static from(apiResponse) {
        // Can't get all subclasses of a class in JS
        let subclasses = [
            SuccessfulApplicantEditionResponse,
            IncorrectWarrantyApplicationNumberResponse,
            FieldErrorResponse,
        ];

        return subclasses.find(subclass => subclass.accepts(apiResponse))
    }

    constructor(apiResponse, fieldMapping) {
        this.apiResponse = apiResponse;
        this.result = apiResponse['resultado'];
        this.fieldMapping = fieldMapping;
    }

    hasErrors() {
        throw new Error("You have to implement the method");
    }

    mapFields(name) {
        return this.fieldMapping[name]
    }
}

export class SuccessfulApplicantEditionResponse extends EditWarrantyApplicantionResponse {
    static accepts(apiResponse) {
        return apiResponse['resultado'] === 'OK';
    }

    warrantyApplicationNumber() {
        return this.apiResponse.solicitud;
    }

    hasErrors() {
        return false
    }
}

export class IncorrectWarrantyApplicationNumberResponse extends EditWarrantyApplicantionResponse {
    static accepts(apiResponse) {
        return apiResponse['resultado'] === 'ERROR' && apiResponse['codigo_de_error'] === 1;
    }

    hasErrors() {
        return true
    }

    errorCode() {
        return this.apiResponse['codigo_de_error'];
    }
}

export class FieldErrorResponse extends EditWarrantyApplicantionResponse {
    static accepts(apiResponse) {
        return apiResponse['resultado'] === 'ERROR' && apiResponse['codigo_de_error'] === 2;
    }

    hasErrors() {
        return true
    }

    errorCode() {
        return this.apiResponse['codigo_de_error'];
    }

    fieldErrors() {
        // const errorField = Object.keys(this.apiResponse.detalle)[0];
        // const errorDetails = this.apiResponse.detalle[errorField][0];
        //
        // return {[this.mapFields(errorField)]: errorDetails};
        let errors = {};
        let errorsInResponse = this.apiResponse.detalle;
        if(errorsInResponse) {
            Object.keys(errorsInResponse).forEach(currentField => {
                if(currentField !== 'non_field_errors') {
                    let mappedField = this.mapFields(currentField);
                    errors[mappedField] = errorsInResponse[currentField][0];
                }
            });
        }

        return errors
    }
}


export class GetRequestHistoryResponse extends Response {
    requestHistory() {
        let requestHistory = [];
        for (let request of this._result['solicitudes']) {
            requestHistory.push({
                'state': request['estado'],
                'progress': request['porcentaje_de_avance'],
                'number': request['nro'],
                'applicant': request['solicitante'],
                'address': request['domicilio'],
                'office': {
                    'name': request['oficina']['nombre'],
                    'address': request['oficina']['domicilio'],
                    'location': request['oficina']['ubicacion'],
                    'phone': request['oficina']['telefono'],
                    'email': request['oficina']['email'],
                }
            });
        }

        return requestHistory;
    }

    mapFields(name) {
        let mapping = {
            codigo_solicitud: 'requestCode'
        };

        return mapping[name]
    }
}


export class SaveDocumentsResponse {
    constructor(response) {
        this._result = response
    }

    hasErrors() {
        return this._result['statusCode'] === 400;
    }
}

export class AddCoApplicantResponse {
    constructor(response) {
        this._result = response
    }

    hasErrors() {
        return this._result['resultado'] === "ERROR";
    }

    newCoApplicantId() {
        return this._result['id']
    }
}

export class GetWarrantyPaymentDetailsResponse extends Response {
    constructor(apiResponse) {
        super(apiResponse);
        this.paymentDetails = apiResponse['resumen_plan_de_pago'];
    }

    _installmentsDetails(installments){
        return installments.map((installment) => {
            return {
                'payment_expiration': installment['vencimiento'],
                'is_paid': installment['pagada'],
                'payment': installment['monto'],
                'payment_mode': installment['modo_de_pago'],
            }
        })
    }

    requestDetails() {
        const request = this.paymentDetails;
        return {
            'payment_mode': request['forma_de_pago'],
            'installments': this._installmentsDetails(request['cuotas']),
            'is_mercadopago_payment_approved': request['esta_el_pago_aprobado_por_mercadopago']
        }
    }
}

export class GetWarrantyPaymentOptionsDetailsResponse extends Response {
    constructor(apiResponse) {
        super(apiResponse);
        this.paymentDetails = apiResponse['politica_de_plan_de_pago'];
    }

    installmentsAllowed() {
        return this.paymentDetails.cantidad_de_cuotas_habilitadas;
    }

}

export class SettlePaymentMethodResponse extends Response {
    constructor(apiResponse) {
        super(apiResponse);
        this._successful = apiResponse['resultado'] === 'OK';
        this.message = apiResponse['mensaje']
    }

    hasErrors() {
        return !this._successful;
    }

    requestDetails() {
        return {'message': this.message}
    }

}

export class InformationOfPaymentMethodResponse extends Response {
    constructor(apiResponse) {
        super(apiResponse);
        this._successful = apiResponse['resultado'] === 'OK';
        this.paymentDetail = apiResponse['object']['plan_de_pago']
    }

    hasErrors() {
        return !this._successful;
    }

    requestDetails() {
        const paymentDetail = this.paymentDetail;
        // debugger;
        return {
            'discount': paymentDetail['porcentaje_descuento'],
            'mercadoPagoDiscount': paymentDetail['porcentaje_descuento_mercadopago'],
            'originalFee': paymentDetail['monto_original'],
            'feeDiscount': paymentDetail['descuento'],
            'fee': paymentDetail['honorarios'],
            'firstSettlementFee': paymentDetail['valor_primer_pago'],
            'otherSettlementsFee': paymentDetail['valor_cuotas_restantes'],
            'administrativeExpenses': paymentDetail['gastos_administrativos']
        }
    }

}

export class MercadoPagoPreferenceMethodResponse extends Response {
    constructor(apiResponse) {
        super(apiResponse);
        this._successful = apiResponse['resultado'] === 'OK';
        this.preferenceDetail = apiResponse['ticket'];
    }

    preferenceId(){
        return this.preferenceDetail.identificador;
    }
}


export class MercadoPagoPaymentMethodResponse extends Response {
    constructor(apiResponse) {
        super(apiResponse);
        this._successful = this._isSuccessful(apiResponse);
        this._ticket = apiResponse['ticket'];
        this._errorDetail = apiResponse['detalle'] || '';
        this.preferenceDetail = apiResponse['mercadopago'];
    }

    _isSuccessful(apiResponse) {
        return apiResponse['resultado'] === 'OK' &&
            apiResponse['ticket']['estado'] !== 'RECHAZADO';
    }

    hasErrors() {
        return !this._successful;
    }

    errorMessage(){
        return this._errorDetail || this._ticket['descripcion'];
    }

    successfulCode(){
        return this._ticket['estado'];
    }

    successfulMessage() {
        return this._ticket['descripcion'];
    }
}

export class MercadoPagoCyberWeekDiscountResponse extends Response {
    constructor(apiResponse) {
        super(apiResponse);
        this._successful = apiResponse['resultado'] === 'OK';
        this._mercadoPagoDiscount = apiResponse['descuento_mercado_pago']
    }

    percentage() {
        return this._mercadoPagoDiscount['porcentaje'];
    }

    discountValidFrom() {
        return this._mercadoPagoDiscount['descuento_valido_desde'];
    }

    discountValidTo() {
        return this._mercadoPagoDiscount['descuento_valido_hasta'];
    }

    extendableUpTo() {
        return this._mercadoPagoDiscount['descuento_extendible_hasta'];
    }

    isActive() {
        return this.percentage() !== 0;
    }

}
