import React from 'react';
import { Button, Input, Modal, Row } from 'react-materialize';
import { containsSubstringLike } from "../lib/others";
import { ProvinceManager } from "../models/managers";
import { FinaerSelect } from "../components/elements/Select";
import { FinaerAutocomplete } from '../components/elements/Autocomplete';
import { app } from '../app/app';
import logo from "../images/logo-finaer.svg";
import Link from "react-router-dom/es/Link";
import { LoaderSection } from '../components/elements/LoaderSection';
import { ConfirmationModal } from '../components/modals/ConfirmationModal';

export class FormLocativeData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            submitted: false,
            error: false,
            formData: {
                alquiler: {},
                expensas_pactadas: {},
                domicilio_inmueble_calle: {},
                domicilio_inmueble_numero: {},
                domicilio_inmueble_piso: {},
                domicilio_inmueble_departamento: {},
                domicilio_inmueble_provincia: {},
                domicilio_inmueble_localidad: {},
                frecuencia_de_indexacion: {},
                tipo_de_alquiler: {},
                duracion_en_meses: {},
                moneda_de_alquiler: {},
                incluye_iva: false,
                observations: {}
            },
            nonFieldErrors: [],
            valid: false,
            loading: true,
            tipo_de_alquiler_options: [],
            frecuencia_de_indexacion_options: [],
            duracion_en_meses_options: [],
            moneda_de_alquiler_options: [],
            tipo_de_indexacion_ARG_options: [],
            tipo_de_indexacion_USD_options: [],
            citySelectOptions: [],
            provinceSelectOptions: [],
            invalidToken: false,
            provinces: [],
            cities: [],
            details: {},
            warranty: {},
            showConfirmationModal: false,
            email: '',
            contact: {}
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleApiResponse = this.handleApiResponse.bind(this);
        this.hasError = this.hasError.bind(this);
        this.onLoadProvincesAndCities = this.onLoadProvincesAndCities.bind(this);
        this.onLoadChoices = this.onLoadChoices.bind(this);
        this.onLoadDetails = this.onLoadDetails.bind(this);
        this.handleProvinceAutoComplete = this.handleProvinceAutoComplete.bind(this);
        this.handleCityAutoComplete = this.handleCityAutoComplete.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);

        this._modal = React.createRef();
        this._form = React.createRef();
    }

    componentDidMount() {
        app.apiClient().getDatosLocacionValidate(this.props.match.params.token).then((result) => {
            if (result?._validate) {
                this.setState({ warranty: result?._warranty, email: result?._email, contact: result?._contact });

                app.apiClient().getProvincesAndCities(this.onLoadProvincesAndCities).then(() => {
                    app.apiClient().getDatosLocacionChoices(this.props.match.params.token, this.onLoadChoices);
                    app.apiClient().getDatosLocacionDetails(this.props.match.params.token, this.onLoadDetails);
                })
            } else {
                this.setState({ invalidToken: true, loading: false });
            }
        }).catch( (e) => {
            this.setState({ error: true, loading: false });
        } )
    }

    onLoadProvincesAndCities(dataLoaded) {
        let provinces = dataLoaded.provinces();
        let cities = dataLoaded.cities();

        this.setState({
            provinces: provinces,
            cities: cities,
            citySelectOptions: this.names(cities),
            provinceSelectOptions: this.names(provinces),
        });
    }

    onLoadChoices(dataLoaded) {
        this.setState({
            tipo_de_alquiler_options: dataLoaded.tipo_de_alquiler(),
            frecuencia_de_indexacion_options: dataLoaded.frecuencia_de_indexacion(),
            duracion_en_meses_options: dataLoaded.duracion_en_meses(),
            moneda_de_alquiler_options: dataLoaded.moneda_de_alquiler(),
            tipo_de_indexacion_ARG_options: dataLoaded.tipo_de_indexacion_ARG(),
            tipo_de_indexacion_USD_options: dataLoaded.tipo_de_indexacion_USD(),
        });
    }

    onLoadDetails(dataLoaded) {
        let details = dataLoaded.details();

        const provincia = this.state.provinces.filter((x) => x._code === details.domicilio_inmueble_localidad.provincia_region)[0];

        let localidad = null;
        if (provincia) {
            const citySelectOptions = this.names(provincia.cities());
            this.setState({  citySelectOptions: citySelectOptions });
            localidad = provincia.cities().filter((x) => x._id === details.domicilio_inmueble_localidad.id)[0];
        }

        this.setState({
            formData: {
                ...this.state.formData,
                domicilio_inmueble_calle: { value: details.domicilio_inmueble_calle || '' },
                domicilio_inmueble_departamento: { value: details.domicilio_inmueble_departamento || '' },
                domicilio_inmueble_numero: { value: details.domicilio_inmueble_numero || '' },
                domicilio_inmueble_piso: { value: details.domicilio_inmueble_piso || '' },
                domicilio_inmueble_provincia: { value: provincia?._name || '' },
                domicilio_inmueble_localidad: { value: localidad?._name || '' },
                tipo_de_alquiler: { value: details.tipo_de_alquiler || '' },
                frecuencia_de_indexacion: { value: details.politica_de_indexacion?.frecuencia_de_indexacion || '' },
                duracion_en_meses: { value: details.politica_de_indexacion?.duracion_en_meses || '' },
                expensas_pactadas: { value: details.terminos_financieros_del_contrato_de_locacion?.expensas_pactadas || 0 },
                alquiler: { value: details.politica_de_indexacion?.montos_por_periodo?.[0]?.alquiler || '' },
                moneda_de_alquiler: { value: details.terminos_financieros_del_contrato_de_locacion?.moneda_de_alquiler || '' },
                tipo_de_indexacion: { value: details.politica_de_indexacion?.tipo_de_indexacion || '' },
                incluye_iva: { value: details.terminos_financieros_del_contrato_de_locacion?.incluye_iva || false },

            },
            details,
            loading: false
        });

        // support for input number format
        const inputNumberElement = document.getElementsByName("expensas_pactadas")[0]
        var labelElement = document.querySelector(`label[for="${inputNumberElement.id}"]`);
        if (labelElement){
            labelElement.classList.add("active");

        }
    }

    names(provincesOrCities) {
        return provincesOrCities.map((item) => item.name());
    }

    handleProvinceAutoComplete(provinceNameOrNull) {
        let formData = this.state.formData;

        if (!provinceNameOrNull) {
            formData.domicilio_inmueble_provincia = { value: provinceNameOrNull, error: 'Campo requerido' };
            this.setState({ formData: formData, citySelectOptions: [] });
        } else {
            formData.domicilio_inmueble_provincia = { value: provinceNameOrNull };
            let citySelectOptions = this.names(this.state.cities);
            if (provinceNameOrNull) {
                const province = ProvinceManager.get(this.state.provinces, provinceNameOrNull);
                citySelectOptions = this.names(province.cities());
    
    
                const provincia = this.state.provinces.filter((x) => x._name === provinceNameOrNull)[0];
                const localidad = provincia.cities()[0];
                formData.domicilio_inmueble_localidad = { value: localidad._name || '' }
            }
    
            this.setState({ formData: formData, citySelectOptions: citySelectOptions });
        }

    }



    handleCityAutoComplete(cityOrNull) {
        let formData = this.state.formData;
        if (!cityOrNull) {
            formData.domicilio_inmueble_localidad = { value: cityOrNull, error: 'Campo requerido' };
        } else {
            formData.domicilio_inmueble_localidad = { value: cityOrNull };
        }
        

        this.setState({ formData: formData });
    }


    confirmSubmit() {
        this.setState({ showConfirmationModal: false }); 

        this.setState({ submitting: true });
        let formData = this.state.formData;
        
        const politica_de_indexacion = {
            frecuencia_de_indexacion: formData.frecuencia_de_indexacion.value,
            duracion_en_meses: +formData.duracion_en_meses.value,
            montos_por_periodo: [
                {   
                    periodo: 1,
                    alquiler: +formData.alquiler.value,
                }
            ],
            tipo_de_indexacion: formData.tipo_de_indexacion.value
        }
        
        const terminos_financieros_del_contrato_de_locacion = {
            expensas_pactadas: +formData.expensas_pactadas.value,
            moneda_de_alquiler: formData.moneda_de_alquiler.value,
            incluye_iva: formData.incluye_iva.value
        }

        const provincia = ProvinceManager.get(this.state.provinces, formData.domicilio_inmueble_provincia.value);
        const localidad = provincia.cities().filter((x) => x._name === formData.domicilio_inmueble_localidad.value)[0];


        let datosLocacionData = {
            solicitud_id: this.state.warranty.id,
            domicilio_inmueble_calle: formData.domicilio_inmueble_calle.value,
            domicilio_inmueble_departamento: formData.domicilio_inmueble_departamento.value,
            domicilio_inmueble_numero: formData.domicilio_inmueble_numero.value,
            domicilio_inmueble_piso: formData.domicilio_inmueble_piso.value,
            tipo_de_alquiler: formData.tipo_de_alquiler.value,
            politica_de_indexacion: politica_de_indexacion,
            terminos_financieros_del_contrato_de_locacion: terminos_financieros_del_contrato_de_locacion,
            domicilio_inmueble_localidad:localidad._id,
            observacion: formData.observations.value
        }

        app.apiClient().updateDatosLocacion(this.props.match.params.token, datosLocacionData, this.handleApiResponse).then((result) => {
            if (result) {
                this.setState({ submitted: true, submitting: false });
            } else {
                this.setState({ error: true, submitting: false });
            }
        });
    }

    handleSubmit(event) {

        if (event) {
            event.preventDefault();
        }

        const valid = this.checkValidity()

        if (valid) {
            this.setState({ showConfirmationModal: true }); 
        }
    }

    handleApiResponse(response) {
        if (!response.hasErrors()) {
            this.setState({ submitted: true });
        } else {
            let formData = this.state.formData;
            let fieldErrors = response.fieldErrors();

            for (let fieldName in fieldErrors) {
                formData[fieldName].error = fieldErrors[fieldName];
            }

            this.setState({ formData: formData, nonFieldErrors: response.nonFieldErrors() });
        }
    }

    handleInputChange(inputName) {
        return (event) => {
            let formData = this.state.formData;
            let input = event.target;
            let form = input.form;
            formData[inputName] = { value: input.value, error: this.isValid(input) };
            this.setState({ formData: formData, valid: form.checkValidity() });
        };
    }

    handleChangeCheckbox(event){
        let formData = this.state.formData;
        formData['incluye_iva'] = { value: event.target.checked };
        this.setState({ formData: formData});
    }

   

    changeValue(inputName, event) {
        let formData = this.state.formData;

        if (inputName === 'moneda_de_alquiler') {
            if (event.target.value === 'USD') {
                formData['tipo_de_indexacion'] = { value: this.state.tipo_de_indexacion_USD_options[0].value };
            } else {
                formData['tipo_de_indexacion'] = { value: this.state.tipo_de_indexacion_ARG_options[0].value };
            }
        }

        formData[inputName] = { value: event.target.value };
        this.setState({ formData: formData });
    }


    tipo_de_alquiler_options() {
        return this.state.tipo_de_alquiler_options
    }


    frecuencia_de_indexacion_options() {
        return this.state.frecuencia_de_indexacion_options
    }


    duracion_en_meses_options() {
        return this.state.duracion_en_meses_options
    }

    moneda_de_alquiler_options() {
        return this.state.moneda_de_alquiler_options
    }

    tipo_de_indexacion_ARG_options() {
        return this.state.tipo_de_indexacion_ARG_options
    }

    tipo_de_indexacion_USD_options() {
        return this.state.tipo_de_indexacion_USD_options
    }

    isValid(input) {
        return input.checkValidity() ? '' : 'Campo requerido';
    }

    hasError(inputName) {
        let inputHasError = this.state.formData[inputName].error;
        return inputHasError ? this.state.formData[inputName].error : '';
    }

    

    render() {
        return (
            <div className="layout-request begin">
                <header>
                    <Link className="site-logo" to="/">
                        <img src={logo} className="app-logo" alt="logo" />
                    </Link>
                </header>
                <main>
                    {this.displayedContent()}
                {
                    this.state.showConfirmationModal && this.confirmationModal()
                }
                </main>
            </div>
        );
    }

    confirmationModal() {
        
        return <ConfirmationModal onClose={()=> this.setState({ showConfirmationModal: false }) } onConfirm={this.confirmSubmit.bind(this)}
            message={"¿Confirma los datos ingresados?"}/>;
    }

    displayedContent() {
        if (this.state.submitted) {
            return this.renderSuccessMessage();
        } else if ( this.state.invalidToken) {
            return this.renderInvalidToken();
        } else if ( this.state.error) {
            return this.renderErrorMessage();
        } else if (this.state.loading) {
            return this.renderLoadingBox();
        } else {
            return this.renderForm();
        }
    }

    renderLoadingBox() {
        return (
            <div className="information-paids-box col m6 col s12">
                <LoaderSection />
            </div>
        );
    }



    checkValidity() {
        let valid = true
        const requiredMessage = 'Campo requerido'
        const formElements = this._form.current.elements;
        let formData = this.state.formData;

        for (let i = 0; i < formElements.length; i++) {
            const element = formElements[i];
            
            if (element.tagName === 'INPUT' || element.tagName === 'SELECT' || element.tagName === 'TEXTAREA') {
                if (element.required && !element.value) {
                    formData[element.name] = {  error: requiredMessage };
                    valid = false
                }
            }
        }

        // support for autocomplete
        if (!formData['domicilio_inmueble_provincia'].value) {
            formData['domicilio_inmueble_provincia'].error = requiredMessage;
            valid = false
        }

        if (!formData['domicilio_inmueble_localidad'].value) {
            formData['domicilio_inmueble_localidad'].error = requiredMessage;
            valid = false
        }

      

        this.setState({ formData: formData });
        return valid
    }

    renderForm() {
        return (
                <div>
                    <h1 style={{fontSize:'36px', fontWeight: '600'}}>Formulario de Datos Locativos</h1>
                    {
                        this.state.contact?.name &&
                            <h2 style={{fontSize:'25px', fontWeight: '500', marginBottom:'32px'}}>Perteneciente a {this.state.contact?.name} ({this.state.warranty?.code})</h2>
                    }
                    <p style={{fontSize:'18px', fontWeight: '500'}}>Completar los datos locativos según lo indicado en el Contrato de locación</p>
                    
                    <form ref={this._form} noValidate onSubmit={this.handleSubmit}>
                        <div className="middle-bar" style={{padding: '40px', margin:'14px'}}>
                            <div className="middle-bar-content" style={{width: '50em'}}>
                                <Row>
                                    <div className="col m4 s12 react-select ">
                                        <p>Tipo de moneda</p>
                                        <div className="react-select" >
                                            <FinaerSelect
                                                selectedOption={this.state.formData.moneda_de_alquiler.value.toString()}
                                                name="moneda_de_alquiler"
                                                onChange={(event) => this.changeValue('moneda_de_alquiler', event)}
                                                options={this.moneda_de_alquiler_options()}
                                                required={true}

                                            />
                                            {
                                                this.hasError('moneda_de_alquiler') &&
                                                    <span style={{color:"#F44336", fontSize:"12px", marginLeft:"5px"}}>Campo requerido</span>
                                            }
                                        </div>
                                    </div>

                                    <div className='col m4 s12 react-select'>
                                        <Input m={12} s={12} onChange={this.handleInputChange('alquiler')} name="alquiler" label="Monto de alquiler" type="number" min="0" value={this.state.formData.alquiler.value} error={this.hasError('alquiler')} autoFocus required  />
                                        <input id="incluye_iva"
                                            name="incluye_iva"
                                            type="checkbox"
                                            defaultChecked={this.state.formData.incluye_iva.value}
                                            onChange={this.handleChangeCheckbox}
                                        />
                                        <label htmlFor="incluye_iva">Incluye IVA</label>
                                    </div>

                                    <Input m={4} s={12} onChange={this.handleInputChange('expensas_pactadas')} name="expensas_pactadas" label="Expensas actuales" type="number" min="0" value={this.state.formData.expensas_pactadas.value} error={this.hasError('expensas_pactadas')}  required />
                                </Row>
                                <Row>
                                    <Input m={6} s={12} onChange={this.handleInputChange('domicilio_inmueble_calle')} name="domicilio_inmueble_calle" label="Calle" value={this.state.formData.domicilio_inmueble_calle.value} error={this.hasError('domicilio_inmueble_calle')}  required />
                                    <Input m={2} s={4} onChange={this.handleInputChange('domicilio_inmueble_numero')} name="domicilio_inmueble_numero" label="Nro." value={this.state.formData.domicilio_inmueble_numero.value} error={this.hasError('domicilio_inmueble_numero')} required />
                                    <Input m={2} s={4} onChange={this.handleInputChange('domicilio_inmueble_piso')} name="domicilio_inmueble_piso" label="Piso" value={this.state.formData.domicilio_inmueble_piso.value} error={this.hasError('domicilio_inmueble_piso')} />
                                    <Input m={2} s={4} onChange={this.handleInputChange('domicilio_inmueble_departamento')} name="domicilio_inmueble_departamento" label="Depto." value={this.state.formData.domicilio_inmueble_departamento.value} error={this.hasError('domicilio_inmueble_departamento')} />
                                </Row>

                                <Row className="-overlap-fix-above">
                                    <div className={"col input-field s6 -autocomplete"}>
                                        <FinaerAutocomplete
                                            label={'Provincia'}
                                            name="domicilio_inmueble_provincia"
                                            itemToString={item => (item ? item : '')}
                                            selectedItem={this.state.formData.domicilio_inmueble_provincia.value}
                                            items={this.state.provinceSelectOptions}
                                            filterFunction={(item, inputValue) => inputValue.length > 3 && containsSubstringLike(inputValue, item)}
                                            onSelect={this.handleProvinceAutoComplete}
                                            error={this.hasError('domicilio_inmueble_provincia')}
                                            
                                            required
                                        />
                                    </div>
                                    <div className={"col input-field s6 -autocomplete"}>
                                        <FinaerAutocomplete
                                            label={'Ciudad / Localidad'}
                                            name="domicilio_inmueble_localidad"
                                            itemToString={item => (item ? item : '')}
                                            items={this.state.citySelectOptions}
                                            selectedItem={this.state.formData.domicilio_inmueble_localidad.value}

                                            filterFunction={(item, inputValue) => inputValue.length > 3 && containsSubstringLike(inputValue, item)}
                                            onSelect={this.handleCityAutoComplete}
                                            error={this.hasError('domicilio_inmueble_localidad')}
                                            disabled={this.state.formData.domicilio_inmueble_provincia.value ? false : true}
                                            required
                                        />
                                    </div>
                                </Row>

                                <Row>
                                    <div className="col m3 s12 react-select ">
                                        <p>Duración del contrato</p>
                                        <div className="react-select" >
                                            <FinaerSelect
                                                selectedOption={this.state.formData.duracion_en_meses.value.toString()}
                                                name="duracion_en_meses"
                                                onChange={(event) => this.changeValue('duracion_en_meses', event)}
                                                options={this.duracion_en_meses_options()}
                                                required={true}

                                            />
                                            {
                                                this.hasError('duracion_en_meses') &&
                                                    <span style={{color:"#F44336", fontSize:"12px", marginLeft:"5px"}}>Campo requerido</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="col m3 s12 react-select ">

                                        <p>Destino del contrato</p>
                                        <div className="react-select">
                                            <FinaerSelect
                                                selectedOption={this.state.formData.tipo_de_alquiler.value}
                                                name="tipo_de_alquiler"
                                                onChange={(event) => this.changeValue('tipo_de_alquiler', event)}
                                                options={this.tipo_de_alquiler_options()}
                                                required={true}
                                            />
                                            {
                                                this.hasError('tipo_de_alquiler') &&
                                                    <span style={{color:"#F44336", fontSize:"12px", marginLeft:"5px"}}>Campo requerido</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="col m3 s12 react-select ">
                                        <p>Tipo de ajuste</p>
                                        <div >
                                            <FinaerSelect
                                                selectedOption={this.state.formData.frecuencia_de_indexacion.value}
                                                name="frecuencia_de_indexacion"
                                                onChange={(event) => this.changeValue('frecuencia_de_indexacion', event)}
                                                options={this.frecuencia_de_indexacion_options()}
                                                required={true}
                                            />
                                              {
                                                this.hasError('frecuencia_de_indexacion') &&
                                                    <span style={{color:"#F44336", fontSize:"12px", marginLeft:"5px"}}>Campo requerido</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="col m3 s12 react-select ">
                                        <p>Modo de indexación</p>
                                        <div >
                                            <FinaerSelect
                                                key={ this.state.formData.moneda_de_alquiler.value}
                                                selectedOption={this.state.formData.tipo_de_indexacion.value.toString()}
                                                name="tipo_de_indexacion"
                                                onChange={(event) => this.changeValue('tipo_de_indexacion', event)}
                                                options={this.state.formData.moneda_de_alquiler.value === 'ARG' ? this.tipo_de_indexacion_ARG_options() : this.tipo_de_indexacion_USD_options()}
                                                required={true}
                                            />
                                              {
                                                this.hasError('tipo_de_indexacion') &&
                                                    <span style={{color:"#F44336", fontSize:"12px", marginLeft:"5px"}}>Campo requerido</span>
                                            }
                                        </div>
                                    </div>
                                </Row>

                                <Row>
                                <Input m={12} s={12}
                                    label="Observaciones"
                                    onChange={this.handleInputChange('observations')}
                                    name="observations"
                                    error={this.hasError('observations')}
                                    maxLength={500}
                                    value={this.state.formData.observations.value}
                                    type={'textarea'}/>
                                </Row>

                            </div>
                        </div>
                        <Row className="submit-row">
                            <Button className="round-btn" type="submit" disabled={this.state.submitting }>
                                Enviar
                            </Button>
                        </Row>
                    </form>
                </div>
        );
    }

    renderSuccessMessage() {
        return (
            <p>
                La información ha sido cargada exitosamente. Un asesor comercial te contactará a la brevedad. Muchas Gracias.
            </p>
        );
    }

   

    renderInvalidToken() {
        return (
            <p>
                 La información ha sido enviada anteriormente. Un asesor comercial te contactará a la brevedad. Muchas Gracias
            </p>
        );
    }

    renderErrorMessage() {
        return (
            <p>
                Se ha producido un error. Ingresa nuevamente. En caso de persistir el inconveniente comunícate con mail <a href={`mailto:${this.state.email ?? "info@finaersa.com.ar"}`}>{this.state.email ?? "info@finaersa.com.ar"}</a>
            </p>
        );
    }
}
